<template>
  <v-menu
    offset-y
    :close-on-click="true"
    :close-on-content-click="false"
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge :value="newNotifications" bottom color="error" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-icon v-bind="attrs" v-on="on">
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
      <!-- <v-icon v-bind="attrs" v-on="on">
        {{ icons.mdiBellOutline }}
      </v-icon> -->
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar class="ps-user-notifications" :options="perfectScrollbarOptions">
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item class="d-flex">
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Notifications</span>
              <v-chip class="v-chip-light-bg primary--text font-weight-semibold" small>
                {{ `${newNotifications} New` }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(notification, index) in sortedNotifications">
            <v-list-item :key="notification.title" link @click="openChangesModal(notification.id, notification.ticket)">
              <!-- Avatar -->
              <v-list-item-avatar class="v-avatar-light-bg primary--text justify-center" size="60">
                <v-row no-gutters>
                  <v-col v-for="(unit, unitIndex) in notification.units">
                    <span :class="notification.units.length === 1 ? 'text-xl' : 'text-xs'">{{ unit }}</span>
                  </v-col>
                </v-row>
              </v-list-item-avatar>
              <!-- <v-list-item-icon color="red" size="12"></v-list-item-icon> -->

              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ notification.title }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle class="text-sm"> -->
                <v-chip small :color="`${notification.changesPending ? '#E0B341' : '#37665a'}`">
                  {{ notification.changesPending ? 'Changes Awaiting Review' : 'All Changes Reviewed' }}
                </v-chip>
                <!-- </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-badge v-if="!notification.viewed" bottom color="error" overlap class="ml-8" dot> </v-badge>
              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ formatDate(notification.date) }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <!-- <v-list-item key="read-all-btn" class="read-all-btn-list-item">
            <v-btn block color="primary"> Read All Notifications </v-btn>
          </v-list-item> -->
        </v-list>
      </perfect-scrollbar>
      <v-dialog
        v-if="selectedNotification"
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="changesModal"
      >
        <!-- Dialog Content -->
        <v-card>
          <!-- <v-toolbar color="primary" dark>Opening from the bottom</v-toolbar> -->
          <v-card-text>
            <!-- <div class="text-h5 pa-2 text-center">{{ notification.title }}</div> -->
          </v-card-text>
          <v-card-text class="text-center text-h5"> Summary of Changes </v-card-text>
          <v-card-text class="text-center text-h6">
            {{
              selectedNotification.ticketNum
                ? `Ticket # ${selectedNotification.ticketNum}`
                : `Job # ${selectedNotification.jobID}`
            }}
          </v-card-text>
          <div v-for="(change, changeIndex) in selectedNotification.changes">
            <v-divider class="ml-10 mr-10"></v-divider>
            <div class="ml-10 mr-10" style="border-left: 2px solid #46425f; border-right: 2px solid #46425f">
              <div
                :style="`height: 30px; display:flex; align-items: center; justify-content: space-between;background-color: ${
                  change.approved ? (change.approved.value === false ? '#661E1B' : '#37665a') : '#E0B341'
                }`"
              >
                <h4 class="text-left ml-3">
                  {{ formatDate(change.date) }}
                </h4>
                <h4 class="text-right mr-5">
                  {{
                    change.approved ? (change.approved.value === false ? 'DISPUTED' : 'APPROVED') : 'AWAITING REVIEW'
                  }}
                </h4>
              </div>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">Previous</th>
                      <th class="text-center">Current</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="[name, value] of Object.entries(change).filter(
                        ([x, y]) => x !== 'date' && x !== 'approved',
                      )"
                      :key="name"
                    >
                      <td v-if="name !== 'charges'" class="text-center">{{ value.displayName }}</td>
                      <td v-if="name !== 'charges'" class="text-center">
                        {{
                          name === 'recievedToCu' || name === 'deliverToCu'
                            ? value.previous.name
                            : name === 'ticketDate'
                            ? formatDate(value.previous)
                            : value.previous
                        }}
                      </td>
                      <td v-if="name !== 'charges'" class="text-center">
                        {{
                          name === 'recievedToCu' || name === 'deliverToCu'
                            ? value.current.name
                            : name === 'ticketDate'
                            ? formatDate(value.current)
                            : value.current
                        }}
                      </td>
                      <!-- Charges -->
                      <td v-if="name === 'charges'" class="text-center">
                        {{ value.previous ? (value.current ? 'Edited Charge' : 'Deleted Charge') : 'Added Charge' }}
                      </td>
                      <td v-if="name === 'charges'" class="text-center">
                        <pre class="text-center">
                                    {{
                            value.previous
                              ? `\nProduct Name: ${value.previous.productName}\n Quantity: ${value.previous.quantity}\n Rate: $${value.previous.rate}`
                              : ''
                          }}
                                    </pre
                        >
                      </td>
                      <td v-if="name === 'charges'" class="text-center">
                        <pre class="text-center">
                                  {{
                            value.current
                              ? `\nProduct Name: ${value.current.productName}\n Quantity: ${value.current.quantity}\n Rate: $${value.current.rate}`
                              : ''
                          }}
                                  </pre
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-if="!change.approved" class="text-right mt-3">
                <v-btn color="#37665a" class="mr-5 mb-2" @click="approveChange(changeIndex)"> Approve</v-btn>
                <v-btn color="#661E1B" class="mr-5 mb-2" @click="openDisputeModal(changeIndex)">Dispute</v-btn>
              </div>
              <div v-else>
                <v-divider></v-divider>
                <div v-if="change.approved.value === true">
                  <h5 class="text-right mr-5">
                    {{ `Approved by ${change.approved.userName} on ${formatDate(change.approved.date)}` }}
                  </h5>
                </div>
                <div v-else>
                  <h5 class="text-right mr-5">
                    {{ `Disputed by ${change.approved.userName} on ${formatDate(change.approved.date)}` }}
                  </h5>
                  <h5 class="text-right mr-5">
                    {{ `Dispute Reason: "${change.approved.disputeReason}"` }}
                  </h5>
                </div>
              </div>
            </div>
            <v-divider class="ml-10 mr-10 mb-10"></v-divider>
          </div>
          <v-card-actions class="justify-end">
            <!-- <v-btn text @click="dialog.value = false">Close</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dispute modal -->
      <v-dialog v-model="disputeModal" max-width="500">
        <v-card class="pt-6 pb-2 pr-8 pl-8">
          <v-textarea v-model="disputeReason" label="Reason for Dispute" required outlined dense></v-textarea>
          <v-col class="text-right">
            <v-btn color="#37665a" class="text-lg-right" @click="disputeChange()">
              <span v-if="!emailLoading">Submit</span>
              <half-circle-spinner v-else :animation-duration="1000" :size="20" :color="'white'" />
            </v-btn>
          </v-col>
        </v-card>
      </v-dialog>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline } from '@mdi/js'
import { HalfCircleSpinner } from 'epic-spinners'
import firebase from 'firebase'
import moment from 'moment'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
const axios = require('axios').default

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
    HalfCircleSpinner,
  },
  data() {
    return {
      approved: false,
      jobNotifications: 0,
      ticketNotifications: 0,
      notifications: [],
      modifiedTickets: [],
      modifiedJobs: [],
      changesModal: false,
      disputeModal: false,
      disputeReason: null,
      disputeChangeIndex: null,
      selectedNotification: null,
      units: [],
      perfectScrollbarOptions: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      icons: {
        mdiBellOutline,
      },
      emailLoading: false,
    }
  },
  methods: {
    approveChange(changeIndex) {
      let userData = JSON.parse(sessionStorage.userData)
      // make copy of change object
      let changes = JSON.parse(JSON.stringify(this.selectedNotification.changes))
      changes[changeIndex].approved = {
        value: true,
        userName: userData.name,
        userID: userData.uid,
        date: new Date(Date.now()),
      }
      //   format dates and check for pending changes
      let changesPending = false
      changes.forEach(change => {
        change.date = new Date(change.date)
        if (change.approved) {
          change.approved.date = new Date(change.approved.date)
        } else {
          changesPending = true
        }
      })

      let collection = this.selectedNotification.ticketNum ? 'modifiedTickets' : 'modifiedJobs'
      firebase
        .firestore()
        .collection(`${collection}`)
        .doc(this.selectedNotification.docID)
        .update({ changes: changes })
        .then(() => {
          let index = this.modifiedTicketsAndJobs.findIndex(x => x.docID === this.selectedNotification.docID)
          let index2 = this.notifications.findIndex(x => x.id === this.selectedNotification.docID)
          this.notifications[index2].changesPending = changesPending
          this.modifiedTicketsAndJobs[index].changes = changes
          this.showSuccess('Approved Change')
        })
        .catch(err => {
          this.showError('Error Approving Change')
          console.log(`Error approving change: ${err}`)
        })
    },
    disputeChange: async function () {
      let userData = JSON.parse(sessionStorage.userData)
      // make copy of change object
      let changes = JSON.parse(JSON.stringify(this.selectedNotification.changes))
      changes[this.disputeChangeIndex].approved = {
        value: false,
        userName: userData.name,
        userID: userData.uid,
        date: new Date(Date.now()),
        disputeReason: this.disputeReason,
      }
      //   format dates and check for pending changes
      let changesPending = false
      changes.forEach(change => {
        change.date = new Date(change.date)
        if (change.approved) {
          change.approved.date = new Date(change.approved.date)
        } else {
          changesPending = true
        }
      })

      let collection = this.selectedNotification.ticketNum ? 'modifiedTickets' : 'modifiedJobs'
      //alert user if they are disputing a job that all approved tickets will be unapproved if they dispute
      if (collection === 'modifiedJobs') {
        if (
          !confirm(
            'All approved tickets on this job will be set to unapproved if you dispute this change, do you wish to continue?',
          )
        ) {
          return
        }
      }
      this.emailLoading = true

      firebase
        .firestore()
        .collection(`${collection}`)
        .doc(this.selectedNotification.docID)
        .update({ changes: changes })
        .then(async () => {
          //send the dispute email.
          try {
            let data = {
              userData: JSON.parse(sessionStorage.userData),
              dispute: this.disputeReason,
              selectedTicket: this.selectedNotification,
              disputeData: this.selectedNotification.changes[this.disputeChangeIndex],
            }
            let response = await axios.get(
              // 'http://127.0.0.1:5001/jdcservices-69fd4/us-central1/disputeEmail',
              'https://us-central1-jdcservices-69fd4.cloudfunctions.net/disputeEmail',
              {
                params: { data },
              },
            )
            if (response.status === 200) {
              this.showSuccess('Dispute email has been sent.')
            } else {
              this.showError('Error sending dispute email.')
            }
          } catch (error) {
            this.showError('Error sending dispute email network request.')
            this.emailLoading = false
          }

          try {
            //if the dispute is for a ticket set the ticket to unapproved.
            let userData = JSON.parse(sessionStorage.userData)
            if (this.selectedNotification.ticketNum) {
              axios
                .post(
                  'https://wilardapi.azurewebsites.net/api/Tickets/update-contractor-approval',
                  {
                    ticketNum: this.selectedNotification.ticketNum,
                    approved: 'No',
                    approvedBy: userData.name,
                  },
                  {
                    headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
                    // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
                  },
                )
                .then(response => {
                  if (response.data === 1) {
                    //success
                    this.showSuccess('Ticket unapproved')
                  } else {
                    this.showError('Error calling the unapproved ticket function.')
                    this.emailLoading = false
                  }
                })
                .catch(err => {
                  this.showError('Error calling the unapproved ticket network call.')
                  this.emailLoading = false
                })
            }
            //if the dispute is for a job, alert them how many tickets will be unapproved
            //and ask for confirmation, then unapprove all approved tickets by that contractor on the job
            else {
              //get list of tickets on the job
              axios
                .post(
                  'https://wilardapi.azurewebsites.net/api/Tickets/get-tickets',
                  // 'https://localhost:44370/api/Tickets/get-tickets',
                  {
                    jobID: this.selectedNotification.jobID,
                    order: 'date',
                  },
                  {
                    headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
                    // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
                  },
                )
                .then(response => {
                  //loop through the tickets and get only the tickets that are approved and for the units tied to the users org
                  let ticketsToUnapprove = []
                  if (response.data) {
                    response.data.forEach(ticket => {
                      if (
                        this.units.includes(ticket.unitID) &&
                        ticket.contractorApproved === 'Yes' &&
                        ticket.contractorInvoiced !== 'Yes'
                      ) {
                        ticketsToUnapprove.push(ticket)
                      }
                    })
                  }
                  //unapprove all tickets
                  for (let i = 0; i < ticketsToUnapprove.length; i++) {
                    axios
                      .post(
                        'https://wilardapi.azurewebsites.net/api/Tickets/update-contractor-approval',
                        // 'https://localhost:44370/api/Tickets/update-contractor-approval',

                        {
                          ticketNum: ticketsToUnapprove[i].ticketNum,
                          approved: 'No',
                          approvedBy: userData.name,
                        },
                        {
                          headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
                          // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
                        },
                      )
                      .then(response => {
                        if (response.data === 1) {
                          //success
                          this.showSuccess('Ticket unapproved')
                        } else {
                          this.showError('Error calling the unapproved ticket function.')
                          this.emailLoading = false
                        }
                      })
                      .catch(err => {
                        this.showError('Error calling the unapproved ticket network call.')
                        this.emailLoading = false
                      })
                  }

                  // if (response.data === 1) {
                  //   //success
                  //   this.showSuccess('Ticket unapproved')
                  // } else {
                  //   this.showError('Error calling the unapproved ticket function.')
                  //   this.emailLoading = false
                  // }
                  // this.emailLoading = false
                  this.disputeModal = false
                  this.disputeReason = null
                })
                .catch(err => {
                  this.showError('Error calling the unapproved ticket network call.')
                  this.emailLoading = false
                })
            }
          } catch (err) {
            this.showError('Error unapproving ticket.')
            this.emailLoading = false
            console.log(`Error unapproving ticket: ${err}`)
          }

          let index = this.modifiedTicketsAndJobs.findIndex(x => x.docID === this.selectedNotification.docID)
          this.modifiedTicketsAndJobs[index].changes = changes
          let index2 = this.notifications.findIndex(x => x.id === this.selectedNotification.docID)
          this.notifications[index2].changesPending = changesPending
          this.disputeModal = false
          this.disputeReason = null

          this.showSuccess('Disputed change')
          this.emailLoading = false
        })
        .catch(err => {
          this.showError('Error Disputing Change')
          console.log(`Error disputing change: ${err}`)
          this.emailLoading = false
        })
    },
    openDisputeModal(changeIndex) {
      this.disputeModal = true
      this.disputeChangeIndex = changeIndex
    },
    openChangesModal(id, ticketNum) {
      let collection = ticketNum ? 'modifiedTickets' : 'modifiedJobs'
      this.selectedNotification = this.modifiedTicketsAndJobs.find(x => x.docID === id)
      this.changesModal = true

      //change viewed for that user to true
      let userData = JSON.parse(sessionStorage.userData)
      let viewedIndex = this.selectedNotification.viewed.findIndex(x => x.id === userData.uid)
      if (viewedIndex !== -1 && !this.selectedNotification.viewed[viewedIndex].viewed) {
        this.selectedNotification.viewed[viewedIndex].viewed = true

        this.selectedNotification.allViewed[`${sessionStorage.selectedOrg}`][viewedIndex] =
          this.selectedNotification.viewed[viewedIndex]
        let viewed = this.selectedNotification.allViewed

        firebase
          .firestore()
          .collection(`${collection}`)
          .doc(this.selectedNotification.docID)
          .update({ viewed: viewed })
          .then(() => {
            let index = this.modifiedTicketsAndJobs.findIndex(x => x.docID === this.selectedNotification.docID)
            this.modifiedTicketsAndJobs[index].viewed = this.selectedNotification.viewed
            this.modifiedTicketsAndJobs[index].allViewed = viewed
            let index2 = this.notifications.findIndex(x => x.id === this.selectedNotification.docID)
            this.notifications[index2].viewed = true
            if (collection === 'modifiedTickets') {
              this.ticketNotifications--
            } else {
              this.jobNotifications--
            }
          })
          .catch(err => {
            this.showError('Error updating viewed notification')
            console.log(`Error updating viewed notification: ${err}`)
          })
      }
    },
    async getModifiedTickets() {
      this.ticketNotifications = 0
      let userData = JSON.parse(sessionStorage.userData)
      let querySnapshot = await firebase
        .firestore()
        .collection('modifiedTickets')
        .where('orgs', 'array-contains', sessionStorage.selectedOrg)
        .where('jobApproved', '==', true)
        .orderBy('lastEditDate', 'desc')
        .get()
      querySnapshot.forEach(doc => {
        //if a ticket has been invoiced, do not display the notification for it
        if (doc.data().invoiced) {
          return
        }
        let viewedData = doc.data().viewed[`${sessionStorage.selectedOrg}`].find(o => o.id === userData.uid)
        if (!viewedData.viewed) {
          this.ticketNotifications++
        }

        let changesPending = false

        let index = this.modifiedTickets.push(doc.data())
        this.modifiedTickets[index - 1].docID = doc.id
        this.modifiedTickets[index - 1].changes.forEach(change => {
          change.date = change.date.toDate()
          change.approved = change.approved ? change.approved : null
          if (change.ticketDate !== undefined && change.ticketDate !== null) {
            change.ticketDate.current = change.ticketDate.current.toDate()
            change.ticketDate.previous = change.ticketDate.previous.toDate()
          }
          if (change.approved) {
            change.approved.date = change.approved.date.toDate()
          } else {
            changesPending = true
          }
        })
        this.modifiedTickets[index - 1].allViewed = this.modifiedTickets[index - 1].viewed
        this.modifiedTickets[index - 1].viewed = doc.data().viewed[`${sessionStorage.selectedOrg}`]
        this.notifications.push({
          ticket: true,
          id: doc.id,
          units: [doc.data().unitID],
          title: `Changes have been made to Ticket #${doc.data().ticketNum}`,
          changesPending: changesPending,
          date: doc.data().lastEditDate.toDate(),
          viewed: viewedData.viewed,
        })
      })
    },

    async getModifiedJobs() {
      this.jobNotifications = 0
      let userData = JSON.parse(sessionStorage.userData)
      let querySnapshot = await firebase
        .firestore()
        .collection('modifiedJobs')
        .where('orgs', 'array-contains', sessionStorage.selectedOrg)
        .where('jobApproved', '==', true)
        .orderBy('lastEditDate', 'desc')
        .get()
      querySnapshot.forEach(doc => {
        let viewedData = doc.data().viewed[`${sessionStorage.selectedOrg}`].find(o => o.id === userData.uid)
        if (!viewedData.viewed) {
          this.jobNotifications++
        }

        let changesPending = false

        let index = this.modifiedJobs.push(doc.data())
        this.modifiedJobs[index - 1].docID = doc.id
        this.modifiedJobs[index - 1].changes.forEach(change => {
          change.date = change.date.toDate()
          change.approved = change.approved ? change.approved : null
          if (change.approved) {
            change.approved.date = change.approved.date.toDate()
          } else {
            changesPending = true
          }
        })
        this.modifiedJobs[index - 1].allViewed = this.modifiedJobs[index - 1].viewed
        this.modifiedJobs[index - 1].viewed = doc.data().viewed[`${sessionStorage.selectedOrg}`]
        //get all units on the notification that are also related to the selected org
        let relevantUnits = doc.data().unitIDs.filter(element => this.units.includes(element))

        //only show 3 units in the notification
        if (relevantUnits.length > 3) {
          relevantUnits = relevantUnits.slice(0, 3)
          relevantUnits[3] = '...'
        }

        this.notifications.push({
          ticket: false,
          id: doc.id,
          units: relevantUnits,
          title: `Changes have been made to Job #${doc.data().jobID}`,
          changesPending: changesPending,
          date: doc.data().lastEditDate.toDate(),
          viewed: viewedData.viewed,
        })
      })
    },
    async getUnits() {
      //get list of units for the users org
      let doc = await firebase.firestore().collection('orgs').doc(`${sessionStorage.selectedOrg}`).get()
      this.units = []
      if (doc.exists) {
        this.units = doc.data().units
      }
    },
    changesPendingCheck(changes) {
      changes.forEach(change => {
        change.approved = change.approved ? change.approved : null
        if (!change.approved) {
          return true
        }
      })
      return false
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY')
    },
    showSuccess: function (message) {
      this.$toasted.show(message, {
        duration: '5000',
        keepOnHover: true,
        type: 'success',
      })
    },
    showError: function (message) {
      this.$toasted.show(message, {
        duration: null,
        keepOnHover: true,
        type: 'error',
      })
    },
  },
  computed: {
    modifiedTicketsAndJobs() {
      return [...this.modifiedTickets, ...this.modifiedJobs].sort(function (a, b) {
        return new Date(b.lastEditDate) - new Date(a.lastEditDate)
      })
    },
    sortedNotifications() {
      return this.notifications.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date)
      })
    },
    newNotifications() {
      return this.ticketNotifications + this.jobNotifications
    },
  },
  async mounted() {
    await this.getUnits()
    this.getModifiedTickets()
    this.getModifiedJobs()
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

h4 {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 3vw;
  }
}
</style>
