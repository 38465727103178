import { mdiChartTimelineVariant, mdiFileDocumentMultipleOutline, mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    title: 'Create Invoice',
    icon: mdiFileDocumentMultipleOutline,
    to: 'create-invoice',
  },
  // {
  //   title: 'Upload Invoice',
  //   icon: mdiCloudUploadOutline,
  //   to: 'upload-invoice',
  // },
  {
    title: 'Report',
    icon: mdiChartTimelineVariant,
    to: 'contractor-balance-sheet',
  },
  // {
  //   title: 'Settings',
  //   icon: mdiCogOutline,
  //   to: 'settings',
  // },
]
